import React, {useContext, useEffect} from 'react';
import DeviceOrientation, {Orientation} from 'react-screen-orientation'
import {Element} from 'react-stylesheet';
import {MdPhoneIphone} from 'react-icons/md';
import styled, {keyframes} from "styled-components";
import {colors, font_size, gutters} from '../../00_base/variables';
import {VBox} from 'react-stylesheet/lib/Box';
import TifzoretBoard from "./tifzoret-board";
import {MobileHidden, MobileOnly} from "../../01_atoms/responsive/responsive";
import {FrameContext} from "../../05_screens/tool/screen-tool";

const ToolTifzoret = (
    {
        correctImages,
        wrongImages,
        number,
        squares_language,
        itemBackgroundColor,
        show_numbers,
        show_letters,
        squareStatus,
        squareBackgroundColor
    }) => {

    const frameContext = useContext(FrameContext)
    const {handleFrameFinish, isFrameFinish} = frameContext;

    useEffect(() => {
        if (!isFrameFinish) {
            frameContext.handleFrameFinish();
        }
    }, [frameContext, handleFrameFinish, isFrameFinish]);

    return (
        <Element background={colors.black} style={{width: "100%"}}>
            <MobileHidden>
                <VBox width={'100%'} height={"100%"}>
                    <Element style={{paddingRight: gutters.md, background: '#fff'}}>
                        {correctImages && correctImages.length > 0 && wrongImages && wrongImages.length > 0 &&
                        <TifzoretBoard hideNumberCode={true}
                                       correctImages={correctImages}
                                       wrongImages={wrongImages}
                                       itemBackgroundColor={itemBackgroundColor}
                                       number_code={number}
                                       squares_language={squares_language}
                                       squareStatus={squareStatus}
                                       squareBackgroundColor={squareBackgroundColor}
                                       show_letters={show_letters}
                                       show_numbers={show_numbers}/>
                        }
                    </Element>
                </VBox>
            </MobileHidden>
            <MobileOnly>
                <DeviceOrientation lockOrientation={'landscape'}>
                    <Orientation orientation='landscape' alwaysRender={false}>
                        <VBox width={'100%'} height={"100%"}>
                            <Element style={{paddingRight: gutters.md, background: '#fff'}}>
                                {correctImages && correctImages.length > 0 && wrongImages && wrongImages.length > 0 &&
                                <TifzoretBoard show_letters={show_letters}
                                               show_numbers={show_numbers}
                                               squareStatus={squareStatus}
                                               squareBackgroundColor={squareBackgroundColor}
                                               hideNumberCode={true}
                                               correctImages={correctImages}
                                               wrongImages={wrongImages}
                                               number_code={number}/>
                                }
                            </Element>
                        </VBox>
                    </Orientation>
                    <Orientation orientation='portrait' alwaysRender={false}>
                        <VBox width={'100%'} height={"100%"} justifyContent={"center"}>
                            <div style={{textAlign: 'center', padding: '30px'}}>
                                <p style={{
                                    color: '#fff',
                                    textAlign: 'center',
                                    fontSize: font_size.lg,
                                    marginTop: 0
                                }}>
                                    יש לסובב את המכשיר
                                </p>
                                <PhoneIconWrapper>
                                    <MdPhoneIphone color={'#fff'}
                                                   size={90}
                                                   style={{
                                                       marginTop: gutters.sm,
                                                       position: 'relative',
                                                   }}/>
                                </PhoneIconWrapper>
                            </div>
                        </VBox>
                    </Orientation>
                </DeviceOrientation>
            </MobileOnly>
        </Element>
    )
}


const Rotate = keyframes` 
        0% {
            transform: rotate(0deg); 
        }
        40% {
            transform: rotate(-90deg);
        }
        80% {
            transform: rotate(0deg); 
        }
        100% {
            transform: rotate(0deg); 
        }
`;

const PhoneIconWrapper = styled.div`
    transform: rotate(90deg);
    transform-origin: center; 
    animation: ${Rotate} 3s infinite;
`;
export default ToolTifzoret;