import {
    ADVENTURE_END,
    ADVENTURE_START,
    CLUE_USE,
    FRAME_FINISH,
    FRAME_START,
    RESET,
    RIDDLE_FINISH,
    RIDDLE_START,
    SET_INITIAL_RIDDLE,
    SET_USER_TOKEN
} from "../actions/journey/journey-actions";

const initialState = {
    user_token: null,
    adventure_start: null,
    adventure_end: null,
};

export const journeyReducer = (state = initialState, action) => {
    const {time, riddleId, frameId, clueId} = action; //gameId
    // const game = `game_${gameId}`;
    let name;
    switch (action.type) {
        case SET_USER_TOKEN:
            return {
                ...state,
                user_token: action.token
            };

        case SET_INITIAL_RIDDLE:
            return {
                ...state,
                [`riddle_${riddleId}_session`]: 0
            };

        case ADVENTURE_START:
            name = "adventure_start";
            return {
                ...initialState,
                [name]: state[name] || time
            };

        case ADVENTURE_END:
            name = "adventure_end";
            return {
                ...state,
                [name]: state[name] || time
            };

        case RIDDLE_START:
            name = `riddle_${riddleId}_start`;
            return {
                ...state,
                [`riddle_${riddleId}_session`]: action.riddle_session,
                [name]: state[name] || time
            };

        case RIDDLE_FINISH:
            name = `riddle_${riddleId}_finish`;
            return {
                ...state,
                [name]: state[name] || time
            };

        case FRAME_START:
            name = `frame_${frameId}_start`;
            return {
                ...state,
                [name]: state[name] || time
            };

        case FRAME_FINISH:
            name = `frame_${frameId}_finish`;
            return {
                ...state,
                [name]: state[name] || time
            };

        case CLUE_USE:
            name = `frame_${frameId}_clue_${clueId}_use`;
            return {
                ...state,
                [name]: state[name] || time
            };

        case RESET:
            return initialState

        default:
            return state;
    }
};

export default journeyReducer;