import React, {useContext} from "react";
import {FrameContext} from "../../05_screens/tool/screen-tool";
import {useSelector} from "react-redux";

export const FrameFinalData = () => {
    const {textColor, correctTitle, correctSubTitle, correctDescription, finalImageUrl, finalImageMaxWidth=320} = useContext(FrameContext);
    const gameData = useSelector(state => state.gameData);

    const {adventure = {}} = gameData || {};

    return (
        <div className="trivia-results" style={{color: textColor}}>
            {correctTitle &&
            <h3 className="trivia-results-title">{correctTitle.replace("{name}", adventure.adventure_name)}</h3>}

            {correctSubTitle &&
            <h5 className="trivia-results-subtitle">{correctSubTitle.replace("{name}", adventure.adventure_name)}</h5>}

            {correctDescription &&
            <p className="trivia-results-description" dangerouslySetInnerHTML={{__html: correctDescription.replace("{name}", adventure.adventure_name)}}/>}

            {finalImageUrl && <img src={finalImageUrl}
                                   width={"100%"}
                                   alt={""}
                                   style={{maxWidth:`${finalImageMaxWidth}px`}}/>}
        </div>
    )
};