import React, {useContext, useEffect} from "react";
import {TriviaContext} from "./tool-trivia";
import {FrameContext} from "../../05_screens/tool/screen-tool";

export const TriviaResults = ({title, subtitle, description, finalImageUrl}) => {
    const {userAnswers, adventureName} = useContext(TriviaContext);
    const {textColor} = useContext(FrameContext);

    const allCorrect = userAnswers.every(item => item.u === item.a);


    return (
        <div className="trivia-results" style={{color: textColor}}>
            <h3 className="trivia-results-title">{title.replace("{name}", adventureName)}</h3>
            <h5 className="trivia-results-subtitle">{subtitle.replace("{name}", adventureName)}</h5>
            <p className="trivia-results-description" dangerouslySetInnerHTML={{__html:description.replace("{name}", adventureName)}}/>
            {allCorrect && finalImageUrl && <img src={finalImageUrl} width={"100%"} alt={""}/>}
        </div>
    )
};