import {
    SET_ADVENTURE_FRAMES,
    SET_SINGLE_ADVENTURE,
    SET_SINGLE_ADVENTURETYPE
} from "../actions/adventure/adventure-actions";


const initialState = {
    adventure: null,
    adventureData: null,
    riddles: null,
    adventureType: null,
    frames: null
};


const adventureReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SINGLE_ADVENTURE :
            return {
                ...state,
                ...action
            };
        case SET_SINGLE_ADVENTURETYPE:
            return {
                ...state,
                adventureType: action.adventureType
            }

        case SET_ADVENTURE_FRAMES:
            return {
                ...state,
                frames: action.frames
            }

        default:
            return state;
    }
};

export default adventureReducer;