import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';

import * as adventureActions from '../../../store/actions/adventure/adventure-actions';
import * as journeyActions from "../../../store/actions/journey/journey-actions";
import {DICTIONARY} from "../../../dictionary/dictionary";
import {ScreenHomepageView} from "./screen-homepage-view";

const ScreenHomepage = (props) => {
    const adventureRouteId = props.match.params.id;
    const gameData = useSelector(state => state.gameData);
    const {adventure, adventureData, riddles, adventureType} = gameData || {};

    const [isLoading, setIsLoading] = useState(!!adventureRouteId);
    const [isPageLoading, setIsPageLoading] = useState(!!adventureRouteId);
    const [error, setError] = useState(null);
    const [counting, setCounting] = useState(null);
    const [passwordConfirmed, setPasswordConfirmed] = useState(!!adventureRouteId);
    const [passcode, setPasscode] = useState(props.passcode || "");
    const [videoEnded, setVideoEnded] = useState(false);

    const history = useHistory();
    const dispatch = useDispatch();
    const {preview} = props;

    const videoStarted = () => {
        let video = document.getElementById("homepage-video");
        video.onended = () => setVideoEnded(true);
    };

    const setAdventure = useCallback((adventureId) => {
        if (!preview) {
            dispatch(journeyActions.reset());
            dispatch(adventureActions.clearApprovedPassword());
        }
        const setAdventureFrame = (adventureStore) => {
            dispatch(adventureActions.setAdventureFrames(adventureStore, () => {

                setPasswordConfirmed(true);
                setIsLoading(false);
                setIsPageLoading(false);
                videoStarted(true);
                if (!preview) {
                    dispatch(journeyActions.adventureStart());
                }
            }))
        };

        if (adventureId) {
            dispatch(adventureActions.setSingleAdventure(adventureId, setAdventureFrame));
        } else {
            setError(true);
            setIsLoading(false);
            setIsPageLoading(false);

        }

    }, [dispatch, preview]);

    const passcodeSubmitHandler = useCallback((value) => {
        setIsLoading(true);
        setError(null);
        if (!preview) {
            dispatch(journeyActions.reset());
            dispatch(adventureActions.clearApprovedPassword());
        }
        const ASIF = passcode.toLowerCase() === "asif";
        const ISRAEL1 = passcode.toLowerCase() === "israel"; //ISRAEL1
        const SHIRIM = passcode.toLowerCase() === 'shirim';
        const SPECIAL_1 = passcode.toLowerCase() === "16jfve";
        const userPasscode = typeof value === "string" ? value :
            SPECIAL_1 ? "jcsbbu" :
                ASIF ? "v6dfpu" :
                    SHIRIM ? 'jb426h' :
                        ISRAEL1 ? '31amqt' : passcode;

        dispatch(adventureActions.getAdventureByPasscode(userPasscode.toLowerCase())).then(setAdventure);
    }, [dispatch, preview, passcode, setAdventure]);

    useEffect(() => {
        if (counting === 0) {
            window.location.href = `/menu/${adventureData?.id}`;
        } else if (counting >= 1) {
            setTimeout(() => setCounting(counting - 1), 1000);
        }
    }, [adventureData, counting]);

    useEffect(() => {
        if (adventureRouteId && parseInt(adventureRouteId)) {
            // localStorage.setItem("adventureId", adventureRouteId);
            // setAdventure(adventureRouteId)
            adventureActions.getAdventure(
                adventureRouteId,
                response => {
                    if (response?.passcode) {
                        passcodeSubmitHandler(response.passcode)
                    } else {
                        setIsLoading(false);
                        setIsPageLoading(false);
                        setPasswordConfirmed(false);
                        history.push("/");
                    }
                },
                () => history.push("/"))
        }
    }, [adventureRouteId, history, passcodeSubmitHandler, setAdventure])


    return <ScreenHomepageView props={props}
                               adventure={adventure}
                               adventureType={adventureType}
                               adventureData={adventureData}
                               riddles={riddles}
                               isPageLoading={isPageLoading}
                               passwordConfirmed={passwordConfirmed}
                               passcode={passcode}
                               error={error}
                               videoEnded={videoEnded}
                               setPasscode={setPasscode}
                               counting={counting}
                               isLoading={isLoading}
                               passcodeSubmitHandler={passcodeSubmitHandler}/>

};

ScreenHomepage.default = {
    title: DICTIONARY.welcome["Hebrew"],
};
ScreenHomepage.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
};

export default ScreenHomepage;