import React, {useState} from 'react';
import {Button, Heading, Image, Paragraph} from '../../../01_atoms';
import {
    arrowWrapper,
    clueButton,
    clueText,
    clueTriggerWrapper,
    clueWrapper,
    continueButton,
    wrapper
} from "./tool-clues-style";
import {useDispatch, useSelector} from "react-redux";
import * as journeyActions from "../../../../store/actions/journey/journey-actions";

const ToolClues = ({cluesData, close, riddleId, frameId, isActive}) => {
    const gameData = useSelector(state => state.gameData);
    const {adventureType = {}} = gameData || {};

    const [activeClue, setActiveClue] = useState(cluesData.length === 1 ? 0 : false);
    const [availableItems, setAvailableItems] = useState(cluesData.map((clue, index) => index));

    const dispatch = useDispatch();

    const handleContinue = () => {
        setAvailableItems([...availableItems, activeClue + 1]);
        dispatch(journeyActions.clueUse(riddleId, frameId, activeClue));
        close(() => setActiveClue(false));
    };

    const currentClue = cluesData[activeClue];
    const cluesLength = cluesData.length;
    const isEnglish = adventureType?.id === 332;

    return (
        <div className="tool-clue" style={{...wrapper, display: isActive ? "flex" : "none"}}>
            <Button onClick={() => close(() => setActiveClue(false))}
                    style={arrowWrapper}>{isEnglish ? "Go back" : "חזור"}</Button>
            {!activeClue && activeClue !== 0 && cluesData.map((clueItem, index) => {
                const isAvailable = availableItems.some(item => item === index);
                const value = index === cluesLength - 1 ? (isEnglish ? "Solution" : "פתרון") : `${isEnglish ? "Clue" : "רמז"} ${index + 1}`;
                return (
                    <div key={index} style={clueTriggerWrapper}>
                        <Button style={{...clueButton, opacity: isAvailable ? 1 : .4}}
                                onClick={() => setActiveClue(index)}>
                            {value}
                        </Button>
                    </div>
                )
            })}

            {!!currentClue &&
            <div style={clueWrapper}>
                {!!currentClue.title &&
                <Heading tag={'h2'} value={currentClue.title} style={clueText('xl')}/>}

                {!!currentClue.subtitle && <Paragraph value={currentClue.subtitle} style={clueText('lg')}/>}

                {!!currentClue.description && <Paragraph value={currentClue.description} style={clueText('md')}/>}

                {!!currentClue.image && <Image src={currentClue.image} width={"300px"} block={true} style={{margin:"10px auto"}} />}


                < Button onClick={handleContinue} style={continueButton}>{isEnglish ? "Continue" : "המשך"}</Button>
                    </div>
                    }

                    </div>
                    )
                    };

                    export default ToolClues;