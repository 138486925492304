import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";
import {ContactListItem} from "./contact-list-item";
import ContactListPhoneCall from "./contact-list-phone-call";
import {letters} from "./phone-numbers";
import {ACTIVE_ITEM} from "./contact-list-local-storage";
import {StyledDiv} from "../../ui/css-general/css-styled";
import "./contact-list.scss";

export const ToolContactList = (
    {
        items,
        textColor,
        handleFrameFinish,
        hideNumbers,
        playIcon,
        itemStyle = {},
        contactsStyle = {},
        toolWrapperStyle = {}
    }) => {
    const activeItemLocalStorage = localStorage.getItem(ACTIVE_ITEM);
    const [activeItem, setActiveItem] = useState(activeItemLocalStorage !== "undefined" ? JSON.parse(activeItemLocalStorage) : "-1");
    const history = useHistory();
     useEffect(() => {
        const isActive = activeItem !== null && activeItem >= 0;

        if (isActive && history.location.hash !== "#ring-ring") {
            history.push("#ring-ring");
            localStorage.setItem(ACTIVE_ITEM, isActive ? activeItem?.toString() : "-1");
        }
    }, [activeItem, history]);

    useEffect(() => {
        handleFrameFinish();
    }, [handleFrameFinish]);

    const renderContacts = items.filter(item => item.name).map((item, i) => {
        return <ContactListItem {...item}
                                key={i}
                                index={i}
                                itemStyle={itemStyle}
                                playIcon={playIcon}
                                hideNumbers={hideNumbers}
                                showScreenCall={() => setActiveItem(i)}/>
    });

    const closePhoneCall = () => {
        localStorage.setItem(ACTIVE_ITEM, "-1");
        history.push(window.pathname);
    };

    const renderLetters = letters.map((letter, i) => <Letter key={i} color={textColor}>{letter}</Letter>);

    return (
        <StyledDiv {...toolWrapperStyle} className={"contact-list"}>
            {activeItem !== null && activeItem >= 0 ?
                <ContactListPhoneCall {...items[activeItem]}
                                      hideNumbers={hideNumbers}
                                      closePhoneCall={closePhoneCall}/>
                :
                <StyledDiv className="contact-list-contacts" {...contactsStyle}>
                    <div className="contact-list-list">{renderContacts}</div>
                    <div className="contact-list-letters">{renderLetters}</div>
                </StyledDiv>
            }
        </StyledDiv>
    )
};

const Letter = styled.div`
    color: ${props => props.color};
`;

ToolContactList.defaultProps = {
    items: [],
    className: ""
};
ToolContactList.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
                name: PropTypes.string,
                icon: PropTypes.string,
                color: PropTypes.string,
                audio: PropTypes.string,
                video: PropTypes.string,
                placeholder: PropTypes.string,
                phone_number: PropTypes.string,
            }
        )),
    className: PropTypes.string
}